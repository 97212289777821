export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOG_OUT = 'LOG_OUT'
export const LOAD_PROFILE = 'LOAD_PROFILE'
export const AUTH_CHECKING = 'AUTH_CHECKING'
export const AUTH_CHECKED = 'AUTH_CHECKED'
export const AUTH_FAILED = 'AUTH_FAILED'
export const AUTH_DENIED = 'AUTH_DENIED'
export const AUTH_AWAITING_APPROVAL = 'AUTH_AWAITING_APPROVAL'
export const AUTH_SENDING_FORM = 'AUTH_SENDING_FORM'

export const SELECTED_VIDEO_REQUEST = 'SELECTED_VIDEO_REQUEST'
export const SELECTED_VIDEO_SUCCESS = 'SELECTED_VIDEO_SUCCESS'
export const SELECTED_VIDEO_FAIL = 'SELECTED_VIDEO_FAIL'
export const HOME_VIDEOS_FAIL = 'HOME_VIDEOS_FAIL'
export const HOME_VIDEOS_REQUEST = 'HOME_VIDEOS_REQUEST'
export const HOME_VIDEOS_SUCCESS = 'HOME_VIDEOS_SUCCESS'

export const CHANNEL_DETAILS_REQUEST = 'CHANNEL_DETAILS_REQUEST'
export const CHANNEL_DETAILS_SUCCESS = 'CHANNEL_DETAILS_SUCCESS'
export const CHANNEL_DETAILS_FAIL = 'CHANNEL_DETAILS_FAIL'

export const COMMENT_LIST_SUCCESS = 'COMMENT_LIST_SUCCESS'
export const COMMENT_LIST_FAIL = 'COMMENT_LIST_FAIL'
export const COMMENT_LIST_REQUEST = 'COMMENT_LIST_REQUEST'

export const SET_SUBSCRIPTION_STATUS = 'SET_SUBSCRIPTION_STATUS'

export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS'
export const CREATE_COMMENT_FAIL = 'CREATE_COMMENT_FAIL'

export const RELATED_VIDEO_REQUEST = 'RELATED_VIDEO_REQUEST'
export const RELATED_VIDEO_SUCCESS = 'RELATED_VIDEO_SUCCESS'
export const RELATED_VIDEO_FAIL = 'RELATED_VIDEO_FAIL'

export const GET_CATEGORIAS_REQUEST = 'GET_CATEGORIAS_REQUEST'
export const GET_CATEGORIAS_SUCCESS = 'GET_CATEGORIAS_SUCCESS'
export const GET_CATEGORIAS_FAIL = 'GET_CATEGORIAS_FAIL'

export const GET_RADIO_REQUEST = 'GET_RADIO_REQUEST'
export const GET_RADIO_SUCCESS = 'GET_RADIO_SUCCESS'
export const GET_RADIO_FAIL = 'GET_RADIO_FAIL'

export const GET_NOTICIAS_REQUEST = 'GET_NOTICIAS_REQUEST'
export const GET_NOTICIAS_SUCCESS = 'GET_NOTICIAS_SUCCESS'
export const GET_NOTICIAS_EMPTY = 'GET_NOTICIAS_EMPTY'
export const GET_NOTICIAS_FAIL = 'GET_NOTICIAS_FAIL'

export const GET_NOTA_REQUEST = 'GET_NOTA_REQUEST'
export const GET_NOTA_SUCCESS = 'GET_NOTA_SUCCESS'
export const GET_NOTA_FAIL = 'GET_NOTA_FAIL'

export const GET_PUBLICIDAD_REQUEST = 'GET_PUBLICIDAD_REQUEST'
export const GET_PUBLICIDAD_SUCCESS = 'GET_PUBLICIDAD_SUCCESS'
export const GET_PUBLICIDAD_FAIL = 'GET_PUBLICIDAD_FAIL'

export const SEARCH_REQUEST = 'SEARCH_REQUEST'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const SEARCH_FAIL = 'SEARCH_FAIL'
export const SEARCH_EMPTY = 'SEARCH_FAIL'

export const WEBINFO_REQUEST = 'WEBINFO_REQUEST'
export const WEBINFO_SUCCESS = 'WEBINFO_SUCCESS'
export const WEBINFO_FAIL = 'WEBINFO_FAIL'

export const CHANNELPASSWORD_REQUEST = 'CHANNELPASSWORD_REQUEST'
export const CHANNELPASSWORD_SUCCESS = 'CHANNELPASSWORD_SUCCESS'
export const CHANNELPASSWORD_FAIL = 'CHANNELPASSWORD_FAIL'